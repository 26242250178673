import {
  Avatar,
  Button,
  Progress,
  Space,
  Spin,
  Table,
  Typography,
  theme,
} from "antd";
import { HLink } from "components/Link";
import { useEffect, useState } from "react";
import { useGoalStore, useRbacStore, useWorkflowStore } from "store";
import { Goal, GoalMeasurement } from "types";

import { getDownloadUrlApi } from "api";
import { ItemsGroup } from "components/ItemsGroup";
import { notification } from "utility/notification";
import { getStyles } from "../../utility/styles";
import { GoalsEdit } from "./GoalsEdit";
import { takeScreenShotByElementId } from "utility";
import { commonIcons } from "assets/icons";
const { Text } = Typography;

interface MutationData {
  isAdd: boolean;
  goal?: Goal;
}

export const GoalsItem = () => {
  const { token } = theme.useToken();
  const goalsLoading = useGoalStore((state) => state.loading);
  const goals = useGoalStore((state) => state.goals);
  const goalMetrics = useGoalStore((state) => state.goalMetrics);
  const users = useRbacStore((state) => state.users);
  const [userProfileUrls, setUserProfileUrls] = useState(
    {} as { [k: string]: string }
  ); // [userId: string]: string

  const getWorkflows = useWorkflowStore((state) => state.getWorkflows);
  const workflows = useWorkflowStore((state) => state.workflows);
  const deleteGoal = useGoalStore((state) => state.deleteGoal);
  const [mutationData, setMutationData] = useState<MutationData | null>(null);

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);

  const getBaseLine = (goalId: string) => {
    return goalMetrics[goalId]?.[0] || 0;
  };

  const getCurrent = (goalId: string) => {
    return goalMetrics[goalId]?.[goalMetrics[goalId].length - 1] || 0;
  };

  const getTarget = (goalId: string) => {
    const goal = goals.find((goal) => goal.id === goalId);
    if (!goal) return 0;
    const rule = goal.target;
    const jsonLogicRule: any = JSON.parse(rule);
    const operands = Object.values(jsonLogicRule)[0] as any[];
    const method = goal.measurement;
    if (method === GoalMeasurement.BOOLEAN) return operands[1] as boolean;
    return operands[1] as number;
  };

  const getProgress = (goalId: string) => {
    const goal = goals.find((goal) => goal.id === goalId);
    if (!goal) return 0;
    const baseLine = getBaseLine(goalId);
    const current = getCurrent(goalId);
    const target = getTarget(goalId);

    if (goal.measurement === GoalMeasurement.BOOLEAN) {
      return !!current === !!target ? 100 : 0;
    } else {
      const v = target as number;
      const progress = (current - baseLine) / (v - baseLine);
      return Math.round(progress * 100);
    }
  };

  useEffect(() => {
    getWorkflows(1, 1000);
  }, []);

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  useEffect(() => {
    const getProfilePictures = async () => {
      const profilePictures: { [k: string]: string } = {};
      for (const userId in users) {
        const user = users[userId];
        if (user?.image) {
          const url = await getDownloadUrlApi(user.image);
          profilePictures[userId] = url.url;
        } else {
          profilePictures[
            userId
          ] = `${user?.firstName[0]} ${user?.lastName[0]}`;
        }
      }
      setUserProfileUrls(profilePictures);
    };
    getProfilePictures();
  }, [users]);

  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deleteGoal(id);
      }, Promise.resolve());
      setSelectedRowKeys([]);
      notification.success({
        message: `Successfully deleted goals`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting users`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    setMutationData(null);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
  };

  const onScreenShotHandler = () => {
    takeScreenShotByElementId("goals-table", "goals", token.colorBorderSecondary)
  }


  return (
    <Spin spinning={goalsLoading}>
      <div
        id="goals-container"
        className={classes.container}
        style={{
          backgroundColor: token.colorBgContainer,
          marginBottom: token.margin,
          width: "100%",
        }}
      >
        <div
          id="goals-header"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: token.margin,
          }}
        >
          <div>
            <Text style={{ fontSize: token.fontSizeHeading4 }}>
              Total Goals ({goals.filter((x) => !x.metric).length})
            </Text>
          </div>
          <Space>
            <Button
              type="primary"
              onClick={() => setMutationData({ isAdd: true })}
            >
              Add
            </Button>
            <Button
              type="primary"
              onClick={() => onDelete(selectedRowKeys)}
              disabled={!selectedRowKeys.length}
            >
              Delete
            </Button>
            <Button
              type="primary"
              onClick={() => onScreenShotHandler()}
            >
              Capture
            </Button>
          </Space>
        </div>

        <div id={"goals-table"} style={{ width: "100%" }}>
          <Table
            rowKey={"id"}
            key={`goal-table`}
            rowSelection={rowSelection}
            dataSource={goals.filter((x) => !x.metric)}
            columns={[
              {
                title: "Title",
                render: (_: any, record: Goal) => {
                  return (
                    <HLink
                      onClick={() => {
                        setMutationData({
                          isAdd: false,
                          goal: record,
                        });
                      }}
                      text={record.title}
                      tooltip={record.description}
                    />
                  );
                },
              },
              {
                title: "Owner",
                dataIndex: "owner",
                key: "owner",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: token.marginXS,
                    }}
                  >
                    <Avatar
                      src={
                        userProfileUrls[record.owner] ||
                        `${users[record.owner]?.firstName[0]} ${
                          users[record.owner]?.lastName[0]
                        }`
                      }
                      crossOrigin="anonymous" 
                    ></Avatar>
                    <Text>
                      {`${users[record.owner]?.firstName} ${
                        users[record.owner]?.lastName
                      }`}
                    </Text>
                  </div>
                ),
              },
              {
                title: "Members",
                dataIndex: "members",
                key: "members",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: token.marginXS,
                    }}
                  >
                    <Avatar.Group maxCount={4}>
                      {record.members?.map((member) => (
                        <Avatar key={member} src={userProfileUrls[member]} crossOrigin="anonymous" />
                      ))}
                    </Avatar.Group>
                  </div>
                ),
              },
              {
                title: "Workflows",
                dataIndex: "workflows",
                key: "workflows",
                render: (_: any, record: Goal) => (
                  <ItemsGroup
                    items={workflows
                      .filter((workflow) =>
                        record.workflows?.includes(workflow.id)
                      )
                      .map((workflow) => ({
                        id: workflow.id,
                        name: workflow.name,
                        description: workflow.description,
                      }))}
                  ></ItemsGroup>
                ),
              },
              {
                title: "Baseline",
                key: "baseline",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ marginRight: token.marginXS }}>
                      {getBaseLine(record.id)}
                    </Text>
                  </div>
                ),
              },
              {
                title: "Current",
                key: "current",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ marginRight: token.marginXS }}>
                      {goalMetrics[record.id]?.length > 0
                        ? goalMetrics[record.id][
                            goalMetrics[record.id].length - 1
                          ]
                        : 0}
                    </Text>
                  </div>
                ),
              },
              {
                title: "Target",
                dataIndex: "target",
                key: "target",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text style={{ marginRight: token.marginXS }}>
                      {getTarget(record.id)}
                    </Text>
                  </div>
                ),
              },
              {
                title: "Achieved",
                key: "achievedPercentage",
                render: (_: any, record: Goal) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Progress
                      percent={getProgress(record.id)}
                      strokeColor={token.colorPrimary}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
        {mutationData && (
          <GoalsEdit
            isAdd={mutationData.isAdd}
            goal={mutationData.goal}
            onClose={onClose}
          />
        )}
      </div>
    </Spin>
  );
};
